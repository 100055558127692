<template>
    <div>
        <Header :title="'心愿清单'" :leftClick="goBack">
        </Header>
        <div style="width: 100%;height: 1px;background-color:#f5f5f5;"></div>
        <div style="width: 100%;padding: 15px;box-sizing: border-box;">
            <KidWantsAlbum v-if="dataList.length > 0" :albums="dataList" @confirmRefuseKidApply="confirmRefuseKidApply">
            </KidWantsAlbum>
            <el-empty v-else></el-empty>
        </div>
    </div>
</template>
<script>
import Header from "../../components/app/Header.vue";
import KidWantsAlbum from "../../components/app/KidWantsAlbum.vue";
import {
    getKidWantAlbums,
    refuseKidWantAlbum
} from '../../api/app'
import {
    getAppUserId
} from '../../utils/store'
export default ({
    name: 'index',
    components: {
        Header,
        KidWantsAlbum
    },
    data() {
        return {
            dataList: []
        }
    },
    mounted() {
        this.requestData()
    },
    methods: {

        requestData() {
            getKidWantAlbums(getAppUserId()).then((res) => {
                let result = res.data
                if (result.code == 0) {
                    this.dataList = result.data
                }
            })
        },
        confirmRefuseKidApply(want_id) {
            refuseKidWantAlbum(want_id).then((res) => {
                this.requestData();
            });
        },
        goBack() {
            this.$router.go(-1)
        }
    }
})
</script>
